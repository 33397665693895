<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="20">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{$t("message.attendance")}}
              </div>
              <div class="block-sarche">
                <div class="header__search d-flex">
                  <el-input
                    :placeholder="$t('message.find')"
                    prefix-icon="el-icon-search"
                    :clearable="true"
                    v-model="filterForm.name"
                    :class="mode ? 'input__day' : 'input__night'"
                    size="small"
                    round
                    style="width: 200px !important"
                    class="mx-1"
                  ></el-input>
                  <select-branch
                    :id="filterForm.branch_id"
                    v-model="filterForm.branch_id"
                    :class="mode ? 'input__day' : 'input__night'"
                    class="mx-1"
                  ></select-branch>
                  <el-date-picker
                    v-model="filterForm.date"
                    type="date"
                    :size="'small'"
                    value-format="yyyy-MM-dd"
                    :class="mode ? 'input__day' : 'input__night'"
                    :placeholder="$t('message.datas')"
                    :clearable="true"
                    class="mx-1"
                  >
                  </el-date-picker>
                  <el-time-picker
                    class="mx-1"
                    :size="'small'"
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.time"
                    :placeholder="$t('message.time')"
                  />
                  <el-button
                    type="primary"
                    size="small"
                    style="width: 150px !important"
                    :class="mode ? 'input__day' : 'input__night'"
                    class="mx-1 mr0__mobile"
                    @click.native="setTime"
                  >
                    {{$t("message.online")}}
                  </el-button>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="4" class="flex-style text-right mbtop5">
            <el-dropdown :class="mode ? 'button__settingsday' : 'button__settingsnight'"   class="setting-cheek ml-2">
              <el-button
                class="padding_none"
                
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown"
              :class="mode ? 'performance__day' : 'performance__night'">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="column.show = !column.show"
                    >{{ column.title }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      <!-- @click="showUseerModal = true" -->
      <div class="card-table-header table-crm-smart" v-loading="loadingData">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered text-align-table mb-5 table__mobile">
          <thead>
            <tr>
              <th rowspan="2" colspan="2">{{$t("message.total")}}</th>
              <th rowspan="2">{{ info.staff_list_count }} (100%)</th>
              <th rowspan="2">
                {{ info.staff_in_count }} ({{
                  ((info.staff_in_count / info.staff_list_count) * 100).toFixed(
                    1
                  )
                }}
                %)
              </th>
               <th rowspan="2">
                {{ info.exit }} ({{
                  ((info.exit / info.staff_list_count) * 100).toFixed(
                    1
                  )
                }}
                %)
              </th>
              <th colspan="2">
                {{ info.not_come_in }} ({{
                  ((info.not_come_in / info.staff_list_count) * 100).toFixed(1)
                }}
                %)
              </th>
            </tr>
            <tr>
              <th>
                {{ info.staff_izn_count }} ({{
                  (
                    (info.staff_izn_count / info.staff_list_count) *
                    100
                  ).toFixed(1)
                }}
                %)
              </th>
              <th>
                {{ info.not_come_in - info.staff_izn_count }} ({{
                  (
                    ((info.not_come_in - info.staff_izn_count) /
                      info.staff_list_count) *
                    100
                  ).toFixed(1)
                }}
                %)
              </th>
            </tr>
          </thead>
        </table>
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table__mobile table-my-code table-bordered text-align-table">
          <thead>
            <tr>
              <th rowspan="2">{{$t("message.department_")}}</th>
              <th rowspan="2">{{$t("message.position")}}</th>
              <th rowspan="2">{{$t("message.all")}}</th>
              <th rowspan="2">{{$t("message.those_present")}}</th>
              <th rowspan="2">{{$t("message.released")}}</th>
              <th colspan="2">{{$t("message.missing")}}</th>
            </tr>
            <tr>
              <th>{{$t("message.with_reason")}}</th>
              <th>{{$t("message.without_reason")}}</th>
            </tr>
          </thead>
          <tbody v-for="(attendance, index) in list" :key="'index-' + index">
            <!-- @dblclick="showUseerModal = true" -->
            <tr>
              <td
                :rowspan="attendance.positions ? attendance.positions.length : 1"
              >
                {{ attendance.name }}
              </td>
              <td>
                {{ attendance.positions[0] ? attendance.positions[0].name : "" }}
              </td>
              <td
                @click="
                  showStaffList(
                    attendance.id,
                    attendance.positions[0].position_id,
                    'list'
                  )
                "
              >
                {{
                  attendance.positions[0]
                    ? attendance.positions[0].cnt_staff
                    : ""
                }}
              </td>
              <td
                @click="
                  showStaffList(
                    attendance.id,
                    attendance.positions[0].position_id,
                    'come'
                  )
                "
              >
                {{
                  attendance.positions[0]
                    ? attendance.positions[0].ishdagilar
                    : ""
                }}
              </td>
              <td
                @click="
                  showStaffList(
                    attendance.id,
                    attendance.positions[0].position_id,
                    'come_exit'
                  )
                "
              >
                {{
                  attendance.positions[0]
                    ? attendance.positions[0].kelib_ketgan
                    : ""
                }}
              </td>
              <td
                @click="
                  showStaffList(attendance.id, attendance.positions[0].position_id, 'izn')
                "
              >
                {{ attendance.positions[0].ruxsati_bor }}
              </td>
              <td
                @click="
                  showStaffList(
                    attendance.id,
                    attendance.positions[0].position_id,
                    'notcome'
                  )
                "
              >
                {{
                  attendance.positions[0].ruxsati_yoq
                }}
              </td>
            </tr>
            <tr
              v-for="(position, j) in attendance.positions"
              :key="'inde-' + j"
              v-if="j > 0"
            >
              <td>{{ position.name }}</td>
              <td @click="showStaffList(attendance.id, position.position_id, 'list')">
                {{ position.cnt_staff }}
              </td>
              <td @click="showStaffList(attendance.id, position.position_id, 'come')">
                {{ position.ishdagilar }}
              </td>
              <td @click="showStaffList(attendance.id, position.position_id, 'come_exit')">
                {{ position.kelib_ketgan }}
              </td>
              <td @click="showStaffList(attendance.id, position.position_id, 'izn')">
                {{ position.ruxsati_bor }}
              </td>
              <td @click="showStaffList(attendance.id, position.position_id, 'notcome')">
                {{
                  position.ruxsati_yoq
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :pagination="pagination"
          ></crm-pagination>
        </div> -->
      </div>
    </div>

    <!-- <el-button
      
      @click="showStaffList(1)"
      type="primary"
      style="margin-left: 16px"
    >
      open
    </el-button> -->

    <el-drawer
      title="I'm outer Drawer"
      :visible.sync="showUseerModal"
      size="80%"
      :with-header="false"
      ref="show-users"
      @opened="drawerOpened('show-users')"
      @closed="drawerClosed('show-users')"
    >
      <div>
        <show-users
          :staff="selected"
          @closeUserModal="closeUserModal"
          :child="child"
          ref="show-users"
        ></show-users>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
import showUsers from "./components/show-users.vue";
import { now } from "moment";
import moment from "moment";

export default {
  mixins: [list],
  name: "attendance",
  components: {
    showUsers,
  },
  data() {
    return {
      showUseerModal: false,
      sarcheBlocks: "",
      filterForm: {},
      date1: null,
      selected: null,
      region: null,
      child: {},
    };
  },
  created() {
    // this.fetchStructure();
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    // this.triggerChanges(this.filterForm.date);
    this.debouncedFetchData = _.debounce(this.fetchData, 500);
  },
  mounted() {},
  computed: {
    ...mapGetters({
      list: "attendanceDepartment/list",
      info: "attendanceDepartment/staffAttendanceList",
      columns: "attendanceDepartment/columns",
      pagination: "attendanceDepartment/pagination",
      statues: "attendanceDepartment/statues",
      filter: "attendanceDepartment/filter",
      sort: "attendanceDepartment/sort",
      mode: "MODE"
    }),
    actions: function () {
      return [];
    },
  },
  methods: {
    ...mapActions({
      updateList: "attendanceDepartment/index",
      updateInfo: "attendanceDepartment/staffAttendanceList",
      setPagination: "attendanceDepartment/setPagination",
      updateSort: "attendanceDepartment/updateSort",
      updateFilter: "attendanceDepartment/updateFilter",
      updateColumn: "attendanceDepartment/updateColumn",
      updatePagination: "attendanceDepartment/updatePagination",
      show: "attendanceDepartment/show",
      empty: "attendanceDepartment/empty",
      delete: "attendanceDepartment/destroy",
      refreshData: "attendanceDepartment/refreshData",
    }),

    //  fetchStructure() {
    //   if (!this.loadContent) {
    //     this.loadContent = true;
    //     this.updateList()
    //       .then((res) => {
    //         this.loadContent = false;
    //       })
    //       .catch((error) => {
    //         this.loadContent = false;
    //       });
    //   }
    // },
    closeUserModal(val) {
      this.showUseerModal = val;
    },
    rangeFunction: function (start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    setTime() {
      // console.log(moment(new Date()).format("YYYY-MM-DD"));
      this.filterForm.date = moment(new Date()).format("YYYY-MM-DD");
      this.filterForm.time = moment(new Date()).format("HH:mm:ss");
    },

    showStaffList(dep, pos, meth) {
      this.child.department_id = dep;
      this.child.position_id = pos;
      this.child.meth = meth;
      this.child.date = this.filterForm.date;
      this.child.time = this.filterForm.time;
      this.showUseerModal = true;
    },

    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },

    profile(model) {
      let route = this.$router.resolve({
        name: "profile",
        params: {
          id: model.id,
        },
      });

      window.open(route.href, "_blank");

      //    OLD CODE
      //   this.$router.push({
      //     name: "profile",
      //     params: { id: model.id },
      //   });
    },
  },
};
</script>
<style>
.text-align-table th,
.text-align-table td {
  text-align: center !important;
  vertical-align: middle;
}
.text-align-table {
  table-layout: fixed;
}
</style>
